import React from 'react';
import { makeStyles } from '@material-ui/core';
import * as qs from 'query-string';

import { TextLink } from '../_components';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }
});


export const ErrorPage = props => {
  const classes = useStyles();

  console.log(props);
  const params = qs.parse(props.location.search);

  let message = "";
  switch (params.code) {
    case '400':
      message += "Bad request received.";
      break;
    case '403':
      message += "Access forbidden.";
      break;
    case '404':
      message += "The page you were looking for could not be found.";
      break;
    case '500':
      message += "An internal server error occurred. We'd better find a new web developer.";
      break;
    default:
      message="";
  }

  return(
    <div className={classes.root}>
      <h1>Uh oh, something went wrong :(</h1>
      { message &&
        <p>{message}</p>
      }
      <p>Click <TextLink href={'/'}>here</TextLink> to head back home.</p>
    </div>
  );
};