export const copy = {
  home: {
    heading: 'Art transportation by artists, for artists',
    detail: 'Find out more about our safe, secure, and speedy services'
  },
  aboutUs: {
    heading: 'About Us',
    tagline: 'We are a modern art logistics courier company based in the historic market town of Marlborough.',
    mainText: ''
  },
  services: {
    transportation: {
      heading: 'Transportation',
      mainText: 'A reliable courier service for artworks up to a maximum of 2m(h) x 3m(l) x 1.5(w) in size.  ' +
      'Our vehicle is specially fitted with a soft floor, and padded sides and roof and can be flexible to facilitate the' +
      ' transport of small to large works of art, whether a painting or sculpture.  All work is delivered to the door,' +
      ' and should your work have special requirements upon delivery (such as installation or signature), we can cater ' +
      'for this also!'
    },
    installation: {
      heading: 'Installation' ,
      mainText: 'We offer an installation service for artworks ranging from individual pieces to installations,' +
        ' sculptures and exhibitions.  '
    },
    packing: {
      heading: 'TransportPacking',
      mainText: 'We offer a packing service for artworks, ranging from bubble wrap to bespoke wooden boxes crate for' +
      ' international transportation. '
    },
  },
  contactDetails: {
    mobileNumber: {
      text: '+44 7810 265 634',
      number: '+447810265634'
    },
    officeNumber: {
      text: '+44 1452 000 000',
      number: '+441452000000'
    },
    address: {
      line1: 'Depart Logistics Ltd',
      line2: '23 Garside Way',
      line3: 'Marlborough',
      line4: 'SN8 4YR'
    },
    email: 'hello@departlogistics.com',
    website: 'www.departlogistics.com'
  }
};