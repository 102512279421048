import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { compose, withProps } from 'recompose';

import { mapStyle } from "./mapStyle";
import pinkMarker from '../images/pink_MarkerD.png';

const center = {lat: 51.422739, lng: -1.727508};

const mapOptions = {
  disableDefaultUI: true,
  styles: mapStyle,
  panControl: false,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  overviewMapControl: false,
  rotateControl: false
};

const DepartMap = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBer14aaci6vDeDMMSQ-kLVgo3kDT5lznY&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%`, width: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    options={ mapOptions }
    defaultZoom={8}
    defaultCenter={center}
  >
    <Marker position={center} icon={pinkMarker} />
  </GoogleMap>
);

export default DepartMap;