import React from 'react';

export const TermsPage = props => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>Coming soon</p>
    </div>
);
};
