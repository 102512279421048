import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px'
  },
  line: {
    width: '85%',
    color: '#df48b1',
    border: '1px solid #df48b1',
    borderTopWidth: '0px',
    backgroundColor: '#df48b1',
    borderColor: '#df48b1'
  }
});

export const SectionBreak = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <hr className={classes.line} />
    </div>
  );
};