import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';

import { TextLink } from '../_components/TextLink';
import InstagramCarousel from "./InstagramCarousel";

const useStyles = makeStyles({
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px'
  },
  textArea: {
    width: '75vw'
  }
});

export const Footer = props => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Divider component={"hr"} style={{ color: 'gray', height: '5px' }} />
      <InstagramCarousel />
      <div className={classes.textArea}>
        <p>Depart Logistics Ltd, 23 Garside Way, Marlborough, SN8 4YR</p>
        <p>
          &copy; Copyright 2019 <span style={{ color: '#df48b1' }}>|</span> All rights reserved <span style={{ color: '#df48b1' }}>|</span> <TextLink href={'/terms'}>Terms & Conditions</TextLink>
        </p>
      </div>
    </div>
  );
};