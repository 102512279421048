import React from 'react';
import { makeStyles } from '@material-ui/core';

import logo from '../logo.jpg';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export const Maintenance = props => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img alt='Depart Art Logistics logo' src={logo} style={{ width: '250px' }}/>
      <p>COMING SOON</p>
    </div>
  );
};
