import React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import '../App.css'
import { NavBar } from './';
import { HomePage } from '../HomePage';
import { TermsPage } from '../TermsPage';
import { Footer } from '../Footer';
import { Maintenance } from "../MaintenancePage";
import { useWindowDimensions } from "./WindowDimensionsProvider";
import { ErrorPage } from "../ErrorPage/ErrorPage";

export const ResponsiveContainer = props => {

  const { width } = useWindowDimensions();

  const mobile = width < props.breakpoint;

  return (
    <Router>
      <div className="App">
      { props.maintenanceMode ?

        <Maintenance mobile={mobile} /> :

        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <NavBar mobile={mobile} />
          </Grid>
          <Grid item xs={12}>
            <Switch>
              <Route exact path='/' render={(props) => <HomePage {...props} mobile={mobile} />} />
              <Route path='/terms' render={(props) => <TermsPage {...props} mobile={mobile} />} />
              <Route path='/error' render={(props) => <ErrorPage {...props} mobile={mobile} />} />
              <Route render={(props) => <ErrorPage {...props} mobile={mobile} />} />
            </Switch>
            <Footer mobile={mobile} />
          </Grid>
        </Grid>
      }
      </div>
    </Router>
  )
};