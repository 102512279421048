import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TextLink } from '../../_components';

export const Transportation = props => {
  const useStyles = makeStyles({
    root: {
      flex: 1,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 5% 0 5%'
    },
    divider: {
      display: 'flex',
      flexDirection: props.mobile ? 'column' : 'row'
    },
    textCell: {
      flex: 1
    },
    imgCell: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Transportation</h1>
      <p>Depart Art Logistics offers a reliable and trustworthy hand delivered transportation service both locally and longer distance within the UK.  For more information please see below or <TextLink to={'contact-us'}>get in touch!</TextLink> </p>
      <p>
        Our vehicle can cater for artworks up to a maximum of <b>1.93m(h) x 3.12m(l) x 1.87(w)</b> in size.
      </p>
    </div>
  );
};