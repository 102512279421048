import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TextLink } from '../../_components';

export const Packing = props => {

  const useStyles = makeStyles({
    root: {
      textAlign: 'left',
      margin: '0 5% 0 5%',
      flex: 1
    },
    divider: {
      display: 'flex',
      flexDirection: props.mobile ? 'column' : 'row',
      justifyContent: 'center',
    }
  });

  const classes = useStyles();

  return (
    <div id='packing' className={classes.root}>
      <h1>Packing & Crating</h1>
      <p>
        Depart Art Logistics offers a bespoke, made to measure crate fabrication service. We are also able to organise and facilitate the correct packing of your artwork either on-site, or from our workshop.
      </p>
      <p>
        Should you need any advice on the packing of your artwork, please <TextLink to={'contact-us'}>get in touch</  TextLink>!
      </p>
    </div>
  );
};