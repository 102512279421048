import React, { useState } from 'react';
import { makeStyles, Drawer, List, ListItem } from '@material-ui/core/';
import { MdMenu } from 'react-icons/md';

import logo from '../logo.jpg';
import { Button, NavLink, SocialTray } from './';

const useStyles = makeStyles({
  mobileRoot: {
    width: '100%',
    margin: '50px 0px 10px 0px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  mobileMenuBtn: {
    maxWidth: '50px',
    maxHeight: '50px',
    alignSelf: 'center'
  },
  root: {
    width: '100%',
    margin: '50px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  gridSide: {
    flex: '1 0 220px',
    display: 'flex',
    alignItems: 'center',
  },
  dropdownContent: {
    display: 'none',
    position: 'absolute',
    minWidth: '160px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 1
  }
});

export const NavBar = props => {

  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const menuList = () => (
    <div role={"presentation"}
         onClick={() => setDrawerOpen(false)}>
      <List>
        {[['HOME', 'home'], ['SERVICES', 'services'], ['ABOUT US', 'about-us'], ['CONTACT', 'contact-us']].map((section, index) => (
          <ListItem button key={section[0]}>
            <NavLink to={section[1]} text={section[0]} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const mobileNav = (
    <div className={classes.mobileRoot}>
      <div className={classes.mobileMenuBtn}>
        <Button text={<MdMenu/>} color='#df48b1' action={() => setDrawerOpen(true)} style={{ justifySelf: 'left' }}/>
        <Drawer open={drawerOpen}
                onClose={() => setDrawerOpen(!drawerOpen)}>
          {menuList()}
        </Drawer>
      </div>
      <div style={{ justifySelf: 'center'}}>
        <a href={'/'}><img alt='Depart Art Logistics' src={logo} style={{ width: '250px' }} /></a>
      </div>
    </div>
  );

  const desktopNav = (
    <div id={'home'} className={classes.root}>
      <div className={classes.grid}>
        <div className={classes.gridSide} style={{ justifyContent: 'flex-end' }}>
          <NavLink style={{ marginRight: '20px' }} text='HOME' to='home' />
          <NavLink style={{ marginRight: '20px' }} text='SERVICES' to='services' />
        </div>
        <div style={{ flex: '0 0', margin: '20px' }}>
          <a href='/'>
            <img alt='Depart Art Logistics' src={logo} style={{ width: '250px' }}/>
          </a>
        </div>
        <div className={classes.gridSide} style={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row'}}>
            <NavLink style={{ marginRight: '20px' }} text='ABOUT US' to='about-us' />
            <NavLink style={{ marginRight: '20px' }} text='CONTACT' to='contact-us' />
          </div>
          <SocialTray style={{ marginRight: '30%' }} />
        </div>
      </div>
    </div>
  );

  return props.mobile ? mobileNav : desktopNav;
};