import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-scroll';

export const TextLink = props => {

  const useStyles = makeStyles({
    link: {
      color: '#df48b1',
      opacity: 1,
      transition: '0.3s',
      '&:hover' : {
        opacity: 0.6
      },
      cursor: 'pointer',
      margin: props.margin ? props.margin : 0
    }
  });

  const classes = useStyles();

  if (props.href) {
    return (
      <a href={props.href} className={classes.link}>{props.children}</a>
    );
  } else if (props.action) {
    return (
      <p className={classes.link} onClick={props.action}>{props.children}</p>
    )
  } else {
    return (
      <Link to={props.to}
            className={classes.link}
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
            style={props.style}>
        {props.children}
      </Link>
    );
  }
};