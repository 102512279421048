import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import mainBannerImg from '../images/portraitbanner.jpg';

const useStyles = makeStyles({
  root: {
    minHeight: '70vh',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundImage: `url(${mainBannerImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: '0px 0px',

  },
  rootMobile: {
    minWidth: '100%',
    height: 0,
    paddingTop: '56%',
    backgroundImage: `url(${mainBannerImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  }
});


export const Welcome = props => {

  const classes = useStyles();

  return (
    <div id={'home'} className={props.mobile ? classes.rootMobile : classes.root}>

    </div>
  );
};