import React from 'react';
import { makeStyles } from '@material-ui/core';

export const BannerImage = props => {
  const useStyles = makeStyles({
    container: {
      minHeight: `${props.minHeight || '70vh'}`,
      minWidth: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundImage: `url(${props.image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: props.mobile ? '0px 0px' : `${props.xOffset || 0} ${props.yOffset || 0}`,
    },
    mobileContainer: {
      minWidth: '100%',
      height: 0,
      paddingTop: `${props.fixedPaddingTop || 0}`,
      backgroundImage: `url(${props.image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain'
    }
  });

  const classes = useStyles();

  return (
    <div className={props.mobile ? classes.mobileContainer : classes.container}>

    </div>
  );
}