import React from 'react';

import './App.css'
import { ResponsiveContainer, WindowDimensionsProvider} from "./_components";

function App() {

  const maintenanceMode = false;
  const breakpoint = 475;

  return (
    <WindowDimensionsProvider>
      <ResponsiveContainer breakpoint={breakpoint} maintenanceMode={maintenanceMode} />
    </WindowDimensionsProvider>
  );
}

export default App;
