import React from 'react';
import { makeStyles } from '@material-ui/core';

import { NavLink } from '../_components';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 3,
    padding: 3,
    opacity: 1,
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      opacity: 0.6
    }
  },
  disabledRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 3,
    padding: 3,
    cursor: 'default',
    opacity: 0.6,
    transition: '0.3s'
  }
});

export const Button = props => {
  const classes = useStyles();

  if (props.to) {
    return (
      <div className={props.disabled ? classes.disabledRoot : classes.root}
           style={{ color: props.color,
             border: `3px solid ${props.color}`,
             fontSize: '15px',
             margin: props.margin
           }}>
        <NavLink to={props.to} text={props.text} />
      </div>
    );
  }

  return (
    <div className={props.disabled ? classes.disabledRoot : classes.root}
         style={{ color: props.color,
                  border: `3px solid ${props.color}`,
                  fontSize: '15px',
                  margin: props.margin
         }}
          onClick={!props.disabled && props.action ? props.action : () => {}}>
      <b>{ props.text }</b>
    </div>
  );
}