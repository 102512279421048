import React from 'react';
import { makeStyles, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';

export const Installations = props => {
  const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      margin: '0 5% 0 5%'
    },
    divider: {
      display: 'flex',
      flexDirection: props.mobile ? 'column' : 'row',
      justifyContent: 'center',
    },
    textCell: {
      flex: 1
    },
    tableCell: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.divider}>
        <div className={classes.textCell}>
          <div className={classes.title}>
            <h1>Installations</h1>
          </div>
          <p>At Depart Art Logistics we offer an installation service for individual or multiple pieces of work, whether that be in residential or gallery settings, and do so with the utmost care, attention to detail and professionalism.

            We offer free site visits for larger installations.
          </p>
        </div>
        <div className={classes.tableCell}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Requirements (*per hour)</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>*One Man + All Standard Fittings</TableCell>
                <TableCell>£60</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>*Two Men + All Standard Fittings</TableCell>
                <TableCell>£80</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};