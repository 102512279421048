import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    textAlign: 'left',
    margin: 20,
    padding: 20,
    maxWidth: 300
  },
});

export const InfoBox = props => {

  const classes = useStyles();

  return (
    <div className={classes.root} style={{ maxWidth: props.maxWidth }}>
      {props.children}
    </div>
  );
};