import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { copy } from '../../copy';
import { TextLink } from '../../_components/index';
import DepartMap from './DepartMap';

export const AboutUs = props => {

  const [collapsed, setCollapsed] = useState(props.mobile);

  const useStyles = makeStyles({
    root: {
      minHeight: '30vh',
      display: 'flex',
      flexDirection: props.mobile ? 'column' : 'row',
      textAlign: 'left',
      margin: '0 5% 0 5%'
    },
    textCell: {
      flex: 1,
      marginRight: '5%'
    },
    mapCell: {
      flex: 1,
    },
    mapCellMobile: {
      flex: 1,
      height: '50vh'
    },
    bulletPoint: {
      marginBottom: '10px'
    },
    readMore: {
      margin: '0px 0px 10px 0px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
      position: 'relative',
      top: '-50px',
      right: '0',
      zIndex: 2,
      minHeight: '30px'
    },
  });

  const classes = useStyles();

  return (
    <div id={'about-us'} className={classes.root}>
      <div className={classes.textCell}>
        <h1>{copy.aboutUs.heading}</h1>
        <div>
          <p>We are a modern art logistics courier company based in the historic market town of Marlborough.</p>

          <p>After many years of helping my wife who is a working artist with the logistics of getting her artwork to various locations, it was recognised that it was difficult to get a good, reliable Art courier. She needed someone who understood the delicacy of the work itself and the requirements needed to deliver it safely to its final destination.</p>

          { collapsed &&
            <div className={classes.readMore}>
              <TextLink action={() => setCollapsed(false)}>Read more</TextLink>
            </div>
          }

          { !collapsed &&
            <div>
              <p>This was where Depart Art Logistics began!</p>

              <p>A specialist art logistics company with a modern approach:</p>

              <ul>
                <li>We listen to your requirements and use our knowledge, equipment and experience to ensure the safety of your Artwork whilst in transit.</li>
                <li>We offer a full service; Transporting, Installation and Packing of Artwork.</li>
                <li>We keep you updated throughout the process. And of course let you know when your artwork has been safely delivered, and send images of any installed artworks so you can see what a great job we have done!</li>
                <li>We don’t believe that getting your artwork to its rightful place should cost the earth.</li>
                <li>We are friendly :)</li>
              </ul>

              <p>We operate mainly in the South of the UK, however please get in contact should your work require a longer journey.</p>

              <p>For a free quote or honest advice, please <TextLink to={'contact-us'}>get in contact</TextLink>.</p>

              <p><b>Olly Bendall</b> | Founder</p>
            </div>
          }
        </div>
      </div>
      <div className={props.mobile ? classes.mapCellMobile : classes.mapCell}>
        <DepartMap />
      </div>
    </div>
  );
};