import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FaPhone, FaInstagram, FaEnvelope } from 'react-icons/fa';

import { copy } from '../copy';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between'
  },
  icon: {
    width: 30,
    height: 30,
    color: '#df48b1',
    margin: 5,
    opacity: 1,
    transition: '0.3s',
    '&:hover': {
      opacity: 0.6
    }
  }
});

export const SocialTray = props => {

  const classes = useStyles();

  return (
    <div className={classes.root} style={props.style}>
      <a href={'tel:' + copy.contactDetails.mobileNumber.number}>
        <FaPhone className={classes.icon} style={{ width: '25px' }} />
      </a>
      <a href='mailto:hello@departlogistics.com'>
        <FaEnvelope className={classes.icon} style={{ width: '30px' }} />
      </a>
      <a href='https://www.instagram.com/departltd/' rel='noopener noreferrer' target='_blank'>
        <FaInstagram className={classes.icon} style={{ width: '30px' }} />
      </a>
    </div>
  );
};