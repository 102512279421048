import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ContactDetailsBox } from "./ContactDetailsBox";
import olly from '../images/olly_edited_color.jpg';

export const Contact = props => {

  const useStyles = makeStyles(theme => ({
    root: {
      padding: 50,
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    divider: {
      alignContent: 'center',
      justifyContent: 'center',
      justifySelf: 'center',
      display: 'flex',
      flexDirection: props.mobile ? 'column' : 'row',
    },
    portraitBox: {
      flex: 1
    },
    storyBox: {
      flex: 1,
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center'
    },
    detailsBox: {
      flex: 1,
      display: 'flex',
      flexFlow: 'column',
      alignSelf: 'center'
    },
    errorBox: {
      backgroundColor: '#df48b1',
      color: 'white',
      margin: 10,
      padding: 4
    },
    link: {
      margin: 20
    },
    sentBox: {
      backgroundColor: '#c4f6bd',
      margin: 10,
      padding: 4
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Contact Us</h1>
      <div id={'contact-us'} className={classes.divider}>
        <div className={classes.portraitBox}>
          <img src={olly}
               style={{ width: 240, alignSelf: 'center' }}
               alt='Portrait of Olly Bendall, Director and Founder at Depart Logistics Ltd'/>
        </div>
        <div className={classes.storyBox}>
          <i>
            "My name is Olly and I have been transporting and installing my wife's artwork for the best part of 10 years.  After a successful corporate career in London, I decided that my real passion was in the art world.  This led me to start Depart Art Logistics in early 2019 and I haven't looked back since."
          </i>
          <p>
            Olly Bendall | <b>Founder</b>
          </p>
        </div>
        <div className={classes.detailsBox}>
          <p>You can reach us using:</p>
          <ContactDetailsBox />
        </div>
      </div>
    </div>
  );
};