import React from 'react';
import { Link } from 'react-scroll';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#df48b1',
    transition: '0.3s',
    '&:hover': {
      opacity: 0.6,
    },
    '&:hover > div': {
      display: 'block'
    },
    cursor: 'pointer'
  }
});

export const NavLink = props => {

  const classes = useStyles();

  if (window.location.pathname === '/') {
    return (
      <Link className={classes.link}
            to={props.to}
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
            style={props.style}>
        {props.text}
      </Link>
    );
  } else {
    return (
      <div className={classes.link} style={props.style}>
        {props.text}
      </div>
    )
  }
};