import React from 'react';

import { Welcome, AboutUs, Services, Installations, Packing, Transportation, Contact } from './';
import { SectionBreak, BannerImage } from "../_components";
import brushImage from './images/paintbanner.jpg';
import vanHighstreet from './images/van_highstreet_close.jpg';
import portraitImage from './images/portraitbanner.jpg';
import vanBars from './images/van_bars.jpg';
import bubblewrap from './images/bubblewrap.jpg';

export const HomePage = props => {
  return (
    <div>
      <Welcome mobile={props.mobile} />
      <SectionBreak />
      <AboutUs mobile={props.mobile} />
      <SectionBreak />
      <BannerImage image={brushImage} fixedPaddingTop={'56%'} mobile={props.mobile} />
      <SectionBreak/>
      <Services mobile={props.mobile} />
      <SectionBreak />
      <BannerImage image={vanHighstreet} fixedPaddingTop={'90%'} minHeight={'80vh'} yOffset={'-440px'} mobile={props.mobile} />
      <SectionBreak />
      <Transportation mobile={props.mobile} />
      <SectionBreak />
      <BannerImage image={vanBars} mobile={props.mobile} fixedPaddingTop={'65%'} yOffset={'-100px'} minHeight={'90vh'} />
      <SectionBreak />
      <Installations mobile={props.mobile} />
      <SectionBreak />
      <BannerImage image={portraitImage} yOffset={'-200px'} fixedPaddingTop={'56%'} mobile={props.mobile} />
      <SectionBreak />
      <Packing mobile={props.mobile}/>
      <SectionBreak />
      <BannerImage image={bubblewrap} yOffset={'-250px'} fixedPaddingTop={'65%'} minHeight={'80vh'} mobile={props.mobile} />
      <SectionBreak />
      <Contact mobile={props.mobile} />
      <SectionBreak />
    </div>
  );
};