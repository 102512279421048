import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FaPhone, FaEnvelope, FaMobile, FaWhatsapp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import { TextLink } from '../../_components/index';
import { copy } from '../../copy';

const structuredContactData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "http://www.departlogistics.com",
    "name": "Depart Logistics Ltd.",
    "alternateName": "Depart Logistics",
    "areaServed": {
      "@type": "Place",
      "address": {
        "country:": "United Kingdom"
      }
    },
    "image": {
      "@type": "ImageObject",
      "caption": "Depart Logistics logo",
      "contentUrl": "https://www.departlogistics.com/static/media/logo.408d4c13.jpg"
    },
    "description": "An art logistics company providing packing, transportation, and installation services in the UK.",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": copy.contactDetails.mobileNumber.text,
      "email": copy.contactDetails.email,
      "contactType": "Customer service",
      "address": {
        "@type": "PostalAddress",
        "addressCountry": "United Kingdom",
        "addressRegion": "Wiltshire",
        "addressLocality": "Marlborough"
      }
    }
};

export const ContactDetailsBox = props => {

  const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  });

  const classes = useStyles();

  const structuredData = JSON.stringify(structuredContactData);

  return (
    <div className={classes.root}>
      <Helmet>
        <script className='structured-data-list' type="application/ld+json">{structuredData}</script>
      </Helmet>
      <TextLink margin={10} href={'tel:' + copy.contactDetails.mobileNumber.number}>
        <FaMobile /> <FaWhatsapp/> {copy.contactDetails.mobileNumber.text}
      </TextLink>
      {false &&       <TextLink margin={10} href={'tel:' + copy.contactDetails.officeNumber.number}>
        <FaPhone />  {copy.contactDetails.officeNumber.text}
      </TextLink>
      }
      <TextLink margin={10} href={"mailto:" + copy.contactDetails.email}>
        <FaEnvelope /> {copy.contactDetails.email}
      </TextLink>
    </div>
  );

};