import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import { TextLink } from "../../_components/";

export const Services = withRouter((props) => {
  const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      margin: '0 5% 0 5%'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }
  });

  const classes = useStyles();

  return (
    <div id={'services'} className={classes.root}>
      <div className={classes.header}>
        <h1>Our Services</h1>
      </div>
      <p>We are a modern art logistics courier company based in the historic market town of Marlborough, England.</p>
      <p>Our services include Transportation, Installation and Packing of Artwork. For more information please see below, or <TextLink to={'contact-us'}>get in touch!</TextLink>
      </p>
    </div>
  );
});