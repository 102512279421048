import React from 'react';
import { makeStyles } from '@material-ui/core';
import Instafeed from '../../node_modules/instafeed.js';

import { TextLink } from '../_components';

const useStyles = makeStyles({
  instaBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    maxWidth: '95vw',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    webkitOverflowScrolling: 'touch',
    msOverflowStyle: '-ms-autohiding-scrollbar',
  }
});

const InstagramCarousel = props => {

  const classes = useStyles();

  const feed = new Instafeed({
    get: 'user',
    userId: '14473404479',
    clientId: '201b63257f9e4fe1b2864111fcaee411',
    accessToken: '14473404479.201b632.e20eb82f168c4c15b2228cefae47122d',
    template: '<div style="margin:5px"><a href="{{link}}" ><img src="{{image}}" alt="instagram post" /></a></div>'
  });
  feed.run();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', fontSize: '15px', marginLeft: 10 }}>
        <TextLink href={'https://instagram.com/departltd'} style={{ justify: 'left' }}>@departltd</TextLink>
      </div>
      <div id="instafeed" className={classes.instaBox}></div>
    </div>
  );
};

export default InstagramCarousel;